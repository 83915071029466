import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const ArticleSlider = () => {
  const swiperContainers = document.querySelectorAll('.article-swiper');
  if (!swiperContainers) {
    return;
  }
  swiperContainers.forEach(slider => {
    const swiperContainer = slider.querySelector('.swiper');
    if (!swiperContainer) {
      return;
    }
    const forwardClass = slider.querySelector('.swiper-button-next');
    const backwardClass = slider.querySelector('.swiper-button-prev');
    new Swiper(swiperContainer, {
      spaceBetween: 30,
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: forwardClass,
        prevEl: backwardClass,
      },
    });
  });
};

export default ArticleSlider;
