import cookies from './components/common/cookies';
import events from './components/common/events';
import { sessionStorage } from './components/common/local-storage';

const wecDomain = window.globalConfig?.wec?.domain;
const lecteraDomain = window.globalConfig?.lectera?.domain;

// TODO: Сделать расширяемым
export const getAuthConfig = () => {
  const result = {};

  function generateAuthConfig(domain, configKey) {
    return {
      getUserUrl: `https://${domain}/auth/users/self`,
      refreshTokenUrl: `https://${domain}/auth/users/refresh-token`,
      authHandler: (token, refreshToken) => {
        cookies.create(window.globalConfig[configKey]?.tokenName, token);
        cookies.create(window.globalConfig[configKey]?.refreshTokenName, refreshToken);
      },
      getToken: () => {
        return cookies.read(window.globalConfig[configKey]?.tokenName);
      },
      getRefreshToken: () => {
        return cookies.read(window.globalConfig[configKey]?.refreshTokenName);
      },
      refreshedToken: () => {
        document.dispatchEvent(new CustomEvent(events.REFRESHED_TOKEN, { detail: { provider: domain } }));
      },
      errAuthHandler: () => {
        cookies.erase(window.globalConfig[configKey]?.tokenName);
        cookies.erase(window.globalConfig[configKey]?.refreshTokenName);
        sessionStorage.remove(window.globalConfig[configKey]?.sessionNameUser);
        document.dispatchEvent(new CustomEvent(events.LOGOUT, { detail: { provider: domain } }));
      },
    };
  }

  if (lecteraDomain) {
    result[lecteraDomain] = generateAuthConfig(lecteraDomain, 'lectera');
  }

  if (wecDomain) {
    result[wecDomain] = generateAuthConfig(wecDomain, 'wec');
  }

  return result;
};
