export function getBlockIndexName(courseBlockIndex) {
  return `slot_${courseBlockIndex}`;
}

export function getName() {
  if (!window?.shared?.globalPrefix || !location.pathname.startsWith(window?.shared?.globalPrefix)) {
    return location.pathname;
  }

  return location.pathname.slice(window?.shared?.globalPrefix.length);
}
