export const disabledScroll = () => {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  if (scrollbarWidth) {
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }
  document.body.style.overflow = 'hidden';
};

export const enableScroll = () => {
  document.body.style.removeProperty('padding-right');
  document.body.style.removeProperty('overflow');
};

export function validateEmail(email) {
  const emailRegex =
    /^(([^<()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.toLowerCase());
}

export function getTranslations(containerId) {
  const container = document.getElementById(containerId);
  const translations = {};

  Object.keys(container.dataset).forEach(key => {
    if (key.startsWith('transl')) {
      const translationKey = key.slice(6).toLowerCase();
      translations[translationKey] = container.dataset[key];
    }
  });

  return translations;
}

export const showPreloader = container => {
  container.innerHTML =
    '<div class="d-flex justify-content-center align-items-center vh-100">\n' +
    '    <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;">\n' +
    '        <span class="visually-hidden"></span>\n' +
    '    </div>\n' +
    '</div>';
};
