import SendClickToGoogleAnalytics from './analytics/click-analytics';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const event = new CustomEvent('loadedCoursesWidget');

const CourseSlider = () => {
  const swiperContainers = document.querySelectorAll('.courses-swiper');

  if (!swiperContainers) {
    return;
  }

  swiperContainers.forEach(slider => {
    const swiperContainer = slider.querySelector('.mySwiper');
    const swiperPagination = slider.querySelector('.swiper-pagination');

    const forwardClass = slider.querySelector('.swiper-button-next');
    const backwardClass = slider.querySelector('.swiper-button-prev');
    new Swiper(swiperContainer, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        nextEl: forwardClass,
        prevEl: backwardClass,
      },
      pagination: {
        el: swiperPagination,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          SendClickToGoogleAnalytics();
          document.dispatchEvent(event);
        },
      },
    });
  });
};

export default CourseSlider;
