const ScriptLoader = (element, src, id) => {
  if (!element) {
    return;
  }
  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = src;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', id);
};

export default ScriptLoader;
