import { getBlockIndexName, getName } from './analytics';

export default function SendClickToGoogleAnalytics() {
  window.dataLayer = window.dataLayer || [];
  const coursesElems = document.querySelectorAll('.courses-swiper');
  if (coursesElems.length === 0 || !window.dataLayer) {
    return;
  }

  const promoObjFactory = obj => {
    return {
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              creative: obj.title,
              name: getName(),
              position: getBlockIndexName(obj.courseBlockIndex) + `_course_${obj.courseIndex}`,
            },
          ],
        },
      },
      eventCallback: function () {
        const link = document.createElement('a');
        link.href = obj.destinationUrl;
        link.target = '_blank';
        link.click();
      },
    };
  };

  coursesElems.forEach((element, courseBlockIndex) => {
    const courseLinks = element.querySelectorAll('a.course-card');
    if (courseLinks.length === 0) {
      return;
    }

    courseLinks.forEach((linkElement, linkIndex) => {
      const courseTitle = linkElement.querySelector('.course-title');
      if (!courseTitle) {
        return;
      }

      // if listener already exit nothing to do here
      if (linkElement.getAttribute('bigBrothers') === 'watch') {
        return;
      }

      linkElement.setAttribute('bigBrothers', 'watch');
      linkElement.addEventListener('click', event => {
        event.preventDefault();
        const promoObject = promoObjFactory({
          courseBlockIndex: courseBlockIndex,
          courseIndex: linkIndex,
          title: courseTitle.textContent,
          destinationUrl: linkElement.attributes.href.textContent || 'https://lectera.com/en/education/course/catalog',
        });

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(promoObject);

        return false;
      });
    });
  });
}
