import { drawProfile } from './drawProfile';
import umsData from '@core/components/common/auth-provider/ums';
import { config } from './config';

const lecteraProvider = umsData(config);

export async function CheckUser() {
  const user = await lecteraProvider.getUser();
  drawProfile(user);
}

export default CheckUser;
