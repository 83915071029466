'use strict';

function Storage(storage) {
  this.storage = storage;
  this.put = function (key, value) {
    this.storage[key] = JSON.stringify(value);
  };

  this.get = function (key) {
    let value = null;
    let strValue = this.storage ? this.storage[key] : null;
    if (strValue) {
      try {
        value = JSON.parse(strValue);
      } catch (e) {
        value = strValue;
      }
    }
    return value;
  };

  this.remove = function (key) {
    this.storage.removeItem(key);
  };
  this.clear = function () {
    this.storage.clear();
  };
}

export const localStorage = new Storage(window.localStorage);
export const sessionStorage = new Storage(window.sessionStorage);
