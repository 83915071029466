'use strict';

export default {
  disable: function (selectors, flag) {
    if (Array.isArray(selectors)) {
      selectors.forEach(function (item) {
        flag ? item.setAttribute('disabled', flag) : item.removeAttribute('disabled');
      });
    } else {
      flag ? selectors.setAttribute('disabled', flag) : selectors.removeAttribute('disabled');
    }
  },

  generateUUID: function () {
    // Public Domain/MIT
    let date = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      date += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (date + Math.random() * 16) % 16 | 0;
      date = Math.floor(date / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  },

  isEmptyObject: function (obj) {
    return JSON.stringify(obj) === '{}';
  },
  shortHostname: function (url) {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl?.hostname;
    } catch (error) {
      return '';
    }
  },

  omitBy: function (obj, predicate) {
    obj = { ...obj };
    Object.entries(obj).forEach(([key, value]) => predicate(value) && delete obj[key]);
    return obj;
  },
};
