import umsData from '@core/components/common/auth-provider/ums';
import { getTranslations } from '../utils';
import { config } from './config';
import { PROFILE_MENU_LINKS, PROFILE_MENU_SVG, DEFAULT_AVATAR, DRAW_PROFILE } from '../constans';
import { Modal } from '../widgets/modal';

const lecteraProvider = umsData(config);

function getItemsMenuProfile(containerId) {
  const t = getTranslations(containerId);
  const { PROFILE_SETTINGS, MY_DASHBOARD, HELP, TERM, EXIT } = PROFILE_MENU_LINKS;
  const { PROFILE_SETTINGS_SVG, MY_DASHBOARD_SVG, HELP_SVG, TERM_SVG, EXIT_SVG } = PROFILE_MENU_SVG;

  return [
    {
      title: t.account,
      link: PROFILE_SETTINGS,
      svg: PROFILE_SETTINGS_SVG,
    },
    {
      title: t.dashboard,
      link: MY_DASHBOARD,
      svg: MY_DASHBOARD_SVG,
    },
    { title: t.help, link: HELP, svg: HELP_SVG },
    {
      title: t.term,
      link: TERM,
      svg: TERM_SVG,
    },
    {
      title: t.exit,
      link: EXIT,
      svg: EXIT_SVG,
    },
  ];
}

function toggleClass(element, className) {
  element.classList.contains(className) ? element.classList.remove(className) : element.classList.add(className);
}

function isShowAuthButton(exit) {
  const authButton = document.getElementById('profileMenuButton');
  const authButtonMobile = document.getElementById('profileMobileMenuButton');
  const noAuthButton = document.getElementById('noUserButton');
  const noAuthButtonMobile = document.getElementById('noUserButtonMobile');
  const coursesButton = document.getElementById('categoriesMenuBtn');

  document.body.classList.add('authenticated');
  toggleClass(authButton, 'open');
  toggleClass(authButtonMobile, 'open');
  toggleClass(noAuthButtonMobile, 'd-none');
  toggleClass(noAuthButton, 'open');
  toggleClass(coursesButton, 'btn-active');
  if (exit) {
    document.body.classList.remove('authenticated');
  }
}

export function drawProfile(user) {
  if (!user) {
    return;
  }

  const t = getTranslations(DRAW_PROFILE.PROFILE_MENU_ID);
  const userElement = document.querySelector(`.${DRAW_PROFILE.USER_BLOCK_CLASS}`);
  isShowAuthButton();

  function createAvatar(tag) {
    const avatarElement = document.createElement(tag);
    avatarElement.className = DRAW_PROFILE.USER_AVATAR_CLASS;
    avatarElement.alt = 'User Avatar';
    if (tag === 'img') {
      avatarElement.src = user.croppedAvatarUri;
    } else {
      avatarElement.innerHTML = DEFAULT_AVATAR;
    }
    userElement.appendChild(avatarElement);
  }

  if (user.croppedAvatarUri) {
    createAvatar('img');
  } else {
    createAvatar('div');
  }

  const nameElement = document.createElement('p');
  nameElement.textContent = user.name;
  nameElement.className = DRAW_PROFILE.USER_FULLNAME_CLASS;
  userElement.appendChild(nameElement);

  const menuElement = document.createElement('ul');
  const menuItems = getItemsMenuProfile('profileMenu');
  menuElement.className = DRAW_PROFILE.USER_LIST_CLASS;

  menuItems.forEach(item => {
    const menuItemElement = document.createElement('li');
    const linkElement = document.createElement('a');
    linkElement.className = DRAW_PROFILE.USER_LIST_LINK_CLASS;
    linkElement.href = item.link;
    linkElement.innerHTML = item.svg;

    if (t.help === item.title) {
      linkElement.classList.add(DRAW_PROFILE.PROFILE_HELP_CLASS);
    }

    if (t.exit === item.title) {
      linkElement.classList.add(DRAW_PROFILE.PROFILE_EXIT_CLASS);
    }

    const textElement = document.createElement('span');
    textElement.textContent = item.title;
    linkElement.appendChild(textElement);

    menuItemElement.appendChild(linkElement);
    menuElement.appendChild(menuItemElement);
  });

  userElement.appendChild(menuElement);

  Modal('profile-help-modal', 'helpModal', 'helpModalClose');
  const exitElement = document.querySelector(`.${DRAW_PROFILE.PROFILE_EXIT_CLASS}`);
  exitElement.addEventListener('click', () => {
    event.preventDefault();
    isShowAuthButton('exit');
    lecteraProvider.logOut();
  });
}
