'use strict';

const showClasses = ['on'];
const mainClass = ['toastify'];

function template(title, message, error) {
  let icon = `${window.globalConfig.prefix ?? ''}/svg/snackbar/success.svg`;
  if (error) {
    icon = `${window.globalConfig.prefix ?? ''}/svg/snackbar/error.svg`;
  }
  return `<div class="toastify__toast-body">
            <div class="toastify__toast-icon toastify--animate-icon toastify__zoom-enter">
                <img alt="status" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" class="" src="${icon}" style="color: transparent;">
            </div>
            <div>
                <div class="toastify-title">${title}</div>
                <div class="toastify-description">${message}</div>
            </div>
         </div>`;
}

const container = document.body.appendChild(document.createElement('div'));
container.classList.add('toast-container', 'toastify-right', 'toastify-top');

class Snackbar {
  constructor() {
    this.duration = 3000;
    this.fadeOut = 2000;
  }

  removeFadeOut(el, speed) {
    el.classList.remove(...showClasses);
    setTimeout(function () {
      el.remove();
    }, speed);
  }

  show(title, message, error) {
    let view = container.appendChild(document.createElement('div'));
    view.classList.add(...mainClass);
    view.innerHTML = template(title, message, error);
    view.classList.add(...showClasses);
    setTimeout(() => this.removeFadeOut(view, this.fadeOut), this.duration);
  }
}

export default new Snackbar();
