import axios from 'axios';
import * as utils from './utils';
import * as sliderStorage from './quiz-local-storage';
import sendEventToGA from './analytics/send-analitics';
import EVENTS from './analytics/events';

export default function quizSlider(htmlElement) {
  const slider = htmlElement,
    slides = slider.querySelectorAll('.quiz-slide'),
    slidesLength = slides.length,
    storage = sliderStorage.getStorage(slider),
    quizTitle = slider.querySelector('.quiz-title h3').textContent,
    quizId = slider.getAttribute('data-id');

  let slideIndex = storage ? storage.slide : 0,
    activeSlide = slides[slideIndex],
    resultSlide = slides[slides.length - 1];

  const dataAnalytics = {
    title: quizTitle,
    id: quizId,
    questionsCount: slidesLength,
    questionNumber: slideIndex,
  };

  let showFlags = false;

  window.addEventListener('scroll', () => {
    if (showFlags !== false) {
      return;
    }
    showFlags = true;
    sendEventToGA({ ...dataAnalytics, eventNameGA: 'showQuizSlider' }, EVENTS.INIT);
  });

  const loadFirstSlide = () => {
    activeSlide.classList.add('fade');

    setTimeout(() => {
      activeSlide.classList.remove('quiz-active-slide', 'fade');
      activeSlide = slides[slideIndex];
      activeSlide.classList.add('quiz-active-slide');
      bullets.change();
    }, 300);
  };

  const loadNextSlide = () => {
    activeSlide.classList.add('fade');
    slideIndex++;

    setTimeout(() => {
      activeSlide.classList.remove('quiz-active-slide', 'fade');
      activeSlide = slides[slideIndex];
      activeSlide.classList.add('quiz-active-slide');
      bullets.change();
    }, 300);
  };

  const getResult = slider => {
    const id = parseInt(slider.dataset.id, 10),
      { type, answers, showCounters, result } = sliderStorage.getStorage(slider);

    if (result && parseInt(showCounters, 10)) {
      utils.updateResultCounters(slider, id);
      return;
    } else if (result) {
      utils.renderResult(slider, result);
      return;
    }

    axios
      .post(window.globalConfig.quiz.result.url, {
        type,
        answers,
        showCounters,
        id,
      })
      .then(response => {
        const {
          data: { result, counters = null },
        } = response;

        utils.renderResult(slider, result, counters);
        sliderStorage.updateStorageWithResult(slider, response);
      })
      .catch(() => {});
  };

  const resetQuiz = slider => {
    slideIndex = sliderStorage.refreshStorage(slider);
    resetInputs(slider);
    loadFirstSlide();
    bullets.show();
    slider.querySelector('.quiz-result-container').classList.add('loading');
  };

  const scrollToTop = slider => {
    window.scroll({
      top: slider.getBoundingClientRect().top + pageYOffset - 100,
      left: 0,
      behavior: 'smooth',
    });
  };

  const resetInputs = (slider, value = 50) => {
    switch (slider.dataset.type) {
      case 'trivia':
      case 'personal':
        utils.getRadio(slider).forEach(radio => {
          radio.checked = false;
        });
        break;
      case 'range':
        utils.getRange(slider).forEach(range => {
          range.value = value;
        });
        break;
    }
  };

  const randomizeAnswers = () => {
    const type = slider.dataset.type;
    let answerBlocks;

    switch (type) {
      case 'trivia':
      case 'personal':
        answerBlocks = slider.querySelectorAll('.quiz-answers ul');
        break;
      case 'range':
        answerBlocks = slider.querySelectorAll('.range-slide-container');
    }

    answerBlocks.forEach(block => {
      const oldAnswers = [];
      let childSelector;

      switch (type) {
        case 'trivia':
        case 'personal':
          childSelector = 'li';
          break;
        case 'range':
          childSelector = '.range-slide';
          break;
      }

      block.querySelectorAll(childSelector).forEach(answer => {
        oldAnswers.push(answer);
        block.removeChild(answer);
      });

      utils.arrayShuffle(oldAnswers);
      oldAnswers.forEach(answer => block.prepend(answer));
    });
  };

  const bullets = {
    items: slider.querySelectorAll('.bullets-container .bullets div'),
    active: null,
    counter: slider.querySelector('.bullets-container .current'),
    show: () => {
      bullets.setActive();
      setTimeout(() => {
        slider.querySelector('.bullets-container.hide').classList.remove('hide');
      }, 100);
    },
    hide: () => {
      slider.querySelector('.bullets-container').classList.add('hide');
    },
    change: () => {
      if (bullets.items.length < slideIndex) {
        return;
      }

      bullets.active.classList.remove('active');
      bullets.setActive();
    },
    setActive: () => {
      if (bullets.active) {
        bullets.active.classList.remove('active');
      }

      bullets.active = bullets.items[slideIndex - 1];
      bullets.active.classList.add('active');
      bullets.counter.textContent = slideIndex;
    },
  };

  if (slider.dataset.random) {
    randomizeAnswers();
  }

  activeSlide.classList.add('quiz-active-slide');

  if (slideIndex && slideIndex <= bullets.items.length) {
    bullets.show();
  }
  if (resultSlide === activeSlide) {
    getResult(slider);
  }

  slider.querySelector('.start-quiz').addEventListener('click', () => {
    loadNextSlide();
    bullets.show();
    sliderStorage.createStorage(slider, slideIndex);
    sendEventToGA({ ...dataAnalytics, eventNameGA: 'startQuizSlider' }, EVENTS.START);
  });

  slider.addEventListener('click', e => {
    if (!e.target.classList.contains('next-slide-button')) {
      return;
    }

    if (sliderStorage.updateStorage(slider, slides, slideIndex)) {
      loadNextSlide();
      sendEventToGA({ ...dataAnalytics, eventNameGA: 'clickQuizSlider' }, EVENTS.CLICK);
      if (window.innerWidth < 640) {
        scrollToTop(slider); // 640 - mobile breakpoint
      }
    }
  });

  slider.querySelector('.get-result').addEventListener('click', () => {
    if (sliderStorage.updateStorage(slider, slides, slideIndex)) {
      getResult(slider);
      bullets.hide();
      loadNextSlide();
      scrollToTop(slider);
      sendEventToGA({ ...dataAnalytics, eventNameGA: 'passedQuizSlider' }, EVENTS.RESULT);
    }
  });

  slider.querySelector('.clear-quiz').addEventListener('click', () => {
    resetQuiz(slider);
    scrollToTop(slider);
  });

  slider.addEventListener('input', e => {
    if (e.target.type !== 'range') {
      return;
    }

    const range = e.target,
      parent = range.closest('.range-card'),
      percentage = parent.querySelector('.percentage');

    percentage.innerText = range.value;
  });
}
