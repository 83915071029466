import { arrayShuffle } from './utils';
import sendEventToGA from './analytics/send-analitics';
import EVENTS from './analytics/events';

export default function flipCardQuiz(quizzes) {
  let resultFlag = false;

  quizzes.forEach(quiz => {
    if (quiz.dataset.random) {
      const answerBlocks = quiz.querySelectorAll('.flipcard-question-container');

      answerBlocks.forEach(block => {
        const oldAnswers = [];

        block.querySelectorAll('.flipcard-answer').forEach(answer => {
          oldAnswers.push(answer);
          block.removeChild(answer);
        });

        arrayShuffle(oldAnswers);
        oldAnswers.forEach(answer => block.prepend(answer));
      });
    }

    const questionCards = quiz.querySelectorAll('.flipcard-container .front');
    const quizBlocks = quiz.querySelectorAll('.quiz-block');
    const quizBlocksLength = quizBlocks.length;
    const quizTitle = quiz.getAttribute('data-title');
    const quizId = quiz.getAttribute('data-id');

    const dataAnalytics = {
      title: quizTitle,
      id: quizId,
      questionsCount: quizBlocksLength,
      questionNumber: 0,
    };

    let showFlags = false;

    window.addEventListener('scroll', () => {
      if (showFlags !== false) {
        return;
      }
      showFlags = true;
      sendEventToGA({ ...dataAnalytics, eventNameGA: 'showQuizFlipcard' }, EVENTS.INIT);
    });

    questionCards.forEach(card => {
      card.addEventListener('click', function () {
        const quizBlock = this.closest('.quiz-block');
        const parent = this.closest('.flipcard-question-container');
        const cardContainer = this.closest('.flipcard-container');
        const cardOpen = parent.querySelector('.flipcard-container.open');
        const explanationDetails = parent.querySelectorAll('.explanation-details > div');
        const cardNumber = quizBlock.getAttribute('data-cardId');

        if (cardOpen) {
          cardOpen.classList.remove('open');
        }

        dataAnalytics.questionNumber = +cardNumber;

        sendEventToGA({ ...dataAnalytics, eventNameGA: 'clickQuizFlipCard' }, EVENTS.CLICK);

        cardContainer.classList.add('open');
        quizBlock.classList.add('answered');

        const answeredCard = quiz.querySelectorAll('.quiz-block.answered');

        if (answeredCard.length === quizBlocks.length) {
          if (resultFlag !== false) {
            return;
          }
          resultFlag = true;
          sendEventToGA({ ...dataAnalytics, eventNameGA: 'passedQuizFlipCard' }, EVENTS.RESULT);
        }

        if (explanationDetails.length === 0) {
          return;
        }

        const explanationDetailsOpen = parent.querySelector('.explanation-details > .open');

        if (explanationDetails.length > 1) {
          const index = Array.from(parent.querySelectorAll('.flipcard-container')).indexOf(cardContainer);
          if (explanationDetailsOpen) {
            explanationDetailsOpen.classList.add('hide');
            setTimeout(() => {
              explanationDetailsOpen.classList.remove('open', 'hide');
              explanationDetails[index].classList.add('open');
            }, 600);
          } else {
            explanationDetails[index].classList.add('open');
          }
        } else {
          explanationDetails[0].classList.add('open');
        }
      });
    });
  });
}
