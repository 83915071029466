import ScriptLoader from '@core/components/common/script-loader';

const LoaderThirdPartScrips = () => {
  ScriptLoader(document.querySelector('.playbuzz'), '//embed.playbuzz.com/sdk.js', 'playbuzz-wjs');
  ScriptLoader(document.querySelector('.instagram-media'), '//platform.instagram.com/en_US/embeds.js', 'instagram-wjs');
  ScriptLoader(document.querySelector('.twitter-tweet'), '//platform.twitter.com/widgets.js', 'twitter-wjs');
  ScriptLoader(
    document.querySelector('.uSocial-Share'),
    '//usocial.pro/usocial/usocial.js?uid=90bcd85132f64690&v=6.1.5',
    'usocial-wjs',
  );
};

export default LoaderThirdPartScrips;
