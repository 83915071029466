import axios from 'axios';
import * as sliderStorage from './quiz-local-storage';

const notifications = {
  mustCheckRadio: {
    ru: 'Выберите один из предложенных вариантов, чтобы продолжить',
    en: 'Please select one of the suggested options to continue',
    de: 'Wählen Sie eine der vorgegebenen Optionen, um fortzufahren',
  },
};

const getRadio = slider => slider.querySelectorAll('[type="radio"]');
const getRange = slider => slider.querySelectorAll('input[type="range"]');

const getSlideRadio = (slides, index) => {
  const radio = slides[index].querySelector('[type="radio"]:checked');
  if (!radio) {
    throwRadioNotification(slides, index);
    return false;
  }
  return radio;
};

const getSlideRange = (slides, index) => slides[index].querySelectorAll('input[type="range"]');

const throwRadioNotification = (slides, index) => {
  const notificationContainer = document.createElement('div'),
    notification = document.createElement('p'),
    notificationText = notifications.mustCheckRadio[window.shared.lang],
    quizAnswers = slides[index].querySelector('.quiz-answers'),
    answersItems = slides[index].querySelectorAll('.quiz-answers li'),
    duration = 1500;

  // duration param used in '.quiz-question-container .notification'
  // selector in `animation` property
  // in file quiz-slides.scss
  // If you change this, you must change parameter in scss file

  notificationContainer.classList.add('notification', 'd-flex', 'justify-content-center', 'align-items-center');

  quizAnswers.classList.add('not-checked');

  notification.classList.add('h2', 'text-center');
  notification.innerText = notificationText;

  notificationContainer.append(notification);

  slides[index].querySelector('.quiz-question-container').append(notificationContainer);

  answersItems.forEach((li, index) => {
    li.style.cssText = `
                animation-delay: ${index * ((duration * 0.8) / answersItems.length) + duration * 0.1}ms;
                animation-duration: ${(duration * 0.8) / answersItems.length}ms
            `;
  });

  setTimeout(() => {
    notificationContainer.remove();
    quizAnswers.classList.remove('not-checked');
  }, duration);
};

const createCountersBlock = (result, counters, resultSlide) => {
  const counterBlock = resultSlide.querySelector('.other-answers-container');
  let countersFragment = document.createDocumentFragment(),
    resultsSum = result.counter;

  counters.forEach(el => {
    resultsSum += el.counter;
  });

  counters.forEach(el => {
    let cntr = document.createElement('div'),
      cntrHead = document.createElement('p'),
      cntrVal = document.createElement('p');

    cntr.classList.add('col-4', 'text-center');

    cntrHead.classList.add('text-bold');
    cntrHead.textContent = el.result_header;
    cntrVal.textContent = resultToPercent(el.counter, resultsSum);

    cntr.append(cntrHead);
    cntr.append(cntrVal);
    countersFragment.append(cntr);
  });

  resultSlide.querySelector('.current-counter').innerHTML = resultToPercent(result.counter, resultsSum);

  while (counterBlock.firstChild) {
    counterBlock.removeChild(counterBlock.firstChild);
  }

  counterBlock.append(countersFragment);
};

const createResultImage = (image, resultSlide) => {
  const splitPath = image.path.split('/'),
    [fileName, fileFormat] = splitPath.pop().split('.'),
    source = document.createElement('source'),
    img = document.createElement('img'),
    picture = document.createElement('picture');

  source.setAttribute('media', '(max-width: 639px)');
  source.setAttribute('srcset', `${splitPath.join('/')}/${fileName}_591x640.${fileFormat}`);

  img.classList.add('thumbnail');
  img.setAttribute('loading', 'lazy');
  img.setAttribute('src', `${splitPath.join('/')}/${fileName}_808x400.${fileFormat}`);
  img.setAttribute('width', '808');
  img.setAttribute('height', '400');

  picture.append(source);
  picture.append(img);

  resultSlide.querySelector('.quiz-result-header').prepend(picture);
};

const updateResultCounters = (slider, id) => {
  axios
    .post(window.globalConfig.quiz.counters.url, {
      id,
    })
    .then(response => {
      const { data } = response,
        { result, counters } = sliderStorage.updateStorageCounters(slider, data);

      renderResult(slider, result, counters);
    })
    .catch(() => {});
};

const renderResult = (slider, result, counters = null) => {
  const resultSlide = slider.querySelector('.quiz-result');
  const oldPicture = resultSlide.querySelector('.quiz-result-header picture');

  if (oldPicture) {
    resultSlide.querySelector('.quiz-result-header').removeChild(oldPicture);
  }

  if (result.image) {
    createResultImage(result.image, resultSlide);
  }

  resultSlide.querySelector('.quiz-result-header .h1').textContent = result.result_header;

  resultSlide.querySelector('.quiz-result-text').innerHTML = result.result_text;

  if (counters) {
    createCountersBlock(result, counters, resultSlide);
  }

  slider.querySelector('.quiz-result-container').classList.remove('loading');
};

const resultToPercent = (val, sum) => ((val * 100) / sum).toFixed(1) + '%';

const arrayShuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export {
  getRadio,
  getRange,
  getSlideRadio,
  getSlideRange,
  createCountersBlock,
  createResultImage,
  updateResultCounters,
  renderResult,
  arrayShuffle,
};
