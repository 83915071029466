'use strict';

import UserManager from '../common/user-manager';
import apiClient from '../common/api';

export default () => {
  const track = document.querySelector('.track');

  if (!track) {
    return;
  }

  const requestData = {
    track_id: UserManager.getTrackId(),
    type: track.dataset.type,
    id: track.dataset.id,
  };

  apiClient.post(window.globalConfig.track.url, requestData);
};
