import { createObjEvent } from './analytics';
import EVENTS from './events';

export default function sendEventToGA(data, event) {
  let eventParams = {};

  window.dataLayer = window.dataLayer || [];

  if (!window.dataLayer) {
    return;
  }

  switch (event) {
    case EVENTS.START:
    case EVENTS.RESULT:
      eventParams = {
        questionsCount: data.questionsCount,
      };
      break;
    case EVENTS.CLICK:
      eventParams = {
        questionsCount: data.questionsCount,
        questionNumber: data.questionNumber,
      };
      break;
    default:
      eventParams = {};
  }

  const obj = createObjEvent(data.eventNameGA, {
    id: data.id,
    title: data.title,
    ...eventParams,
  });

  window.dataLayer.push(obj);
}
