'use strict';

const prefix = '/magazine';
const lecteraDomain = import.meta.env.VITE_LECTERA_MAIN_DOMAIN;

window.globalConfig = {
  prefix: prefix,
  track: {
    url: `${prefix}/api/v1/track`,
    key: 'bigBrother',
  },
  lectera: {
    help: '/api/help',
    domain: lecteraDomain,
    tokenName: 'token',
    refreshTokenName: 'refreshtoken',
    sessionNameUser: 'lecteraUser',
    pulse: `https://${lecteraDomain}/api/user-activity/pulse`,
    aggregate: `https://${lecteraDomain}/aggregate/event`,
  },
  leaflet: {
    token: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
  },
  googleCaptcha: '6LdxNYUbAAAAAJWjMRabZM7zmyg4XiYTQS0TFSic',
  quiz: {
    result: {
      url: `${prefix}/api/v1/quiz-result`,
    },
    counters: {
      url: `${prefix}/api/v1/quiz-result-counters`,
    },
  },
};
