import axios from 'axios';
import cookies from './cookies';
import { getAuthConfig } from '../../auth-config';
import helpers from './helpers';

const authConfig = getAuthConfig();
const isRefreshing = {};
const refreshSubscribers = {};

function subscribeTokenRefresh(domain, resolveCallback) {
  if (!refreshSubscribers[domain]) {
    refreshSubscribers[domain] = [];
  }
  refreshSubscribers[domain].push(resolveCallback);
}

function onRefreshed(domain, token) {
  if (!refreshSubscribers[domain]) {
    return;
  }
  refreshSubscribers[domain].map(resolveCallback => resolveCallback(token));
  refreshSubscribers[domain] = [];
}

function clearSubscribe(domain) {
  if (refreshSubscribers[domain]) {
    refreshSubscribers[domain] = [];
  }
}

const refreshToken = (config, domain) => {
  axios
    .create()
    .post(config.refreshTokenUrl, null, {
      headers: {
        'Refresh-Token': config.getRefreshToken(),
      },
    })
    .then(response => ({ data: response.data, status: response.status }))
    .then(result => {
      if (result.status === 200) {
        const { accessToken, refreshToken } = result.data;
        config.authHandler(accessToken, refreshToken);
        onRefreshed(domain, accessToken);
        if (authConfig[domain]?.refreshedToken) {
          authConfig[domain].refreshedToken();
        }
      }
    })
    .catch(() => {
      clearSubscribe();
      config.errAuthHandler();
    })
    .finally(() => {
      isRefreshing[domain] = false;
    });
};

function createApiClient() {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(config => {
    const requestConfig = {
      headers: {},
      ...config,
    };

    const locale = cookies.read('lang') || window.shared.lang;
    const domain = helpers.shortHostname(requestConfig.url);
    const token = authConfig[domain]?.getToken();

    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`;
    }

    if (locale) {
      requestConfig.headers.locale = locale;
    }

    return requestConfig;
  });

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const { config, response } = error;
      const originalRequest = config;
      const domain = helpers.shortHostname(config?.url);

      if (!authConfig[domain]) {
        return Promise.reject(error);
      }

      if (response.status === 401 || response.status === 419) {
        if (!isRefreshing[domain]) {
          isRefreshing[domain] = true;
          refreshToken(authConfig[domain], domain);
        }

        return new Promise(resolve => {
          subscribeTokenRefresh(domain, token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            axiosInstance.request(originalRequest).then(value => {
              resolve(value);
            });
          });
        });
      }
    },
  );

  return axiosInstance;
}

const apiClient = createApiClient();

export default apiClient;
