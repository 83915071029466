import cookies from "@core/components/common/cookies";

const lang =  cookies.read('lang') || window.shared.lang

export const createObjEvent = (event, obj) => {
    return {
        event,
        data: {
           ...obj,
            langCode: lang,
        },
    }
}
