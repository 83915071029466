'use strict';

import cookies from '../common/cookies';
import { localStorage } from './local-storage';
import jwt_decode from 'jwt-decode';
import * as events from '../../events';
import helpers from './helpers';

const userKey = 'user';
const userAuthKey = 'tokenViewCore';
const redirectedFrom = 'redirected_from';

export default class UserManager {
  static getUser() {
    return localStorage.get(userKey);
  }

  static getToken() {
    return localStorage.get(userAuthKey);
  }

  static storeToken(token) {
    return localStorage.put(userAuthKey, token);
  }

  static getTrackId() {
    let trackId = cookies.read(window.globalConfig.track.key);
    if (!trackId) {
      trackId = helpers.generateUUID();
      cookies.create(window.globalConfig.track.key, trackId, 365);
    }
    return trackId;
  }

  static processLogin(provider) {
    if (!provider) {
      return false;
    }
    localStorage.put(redirectedFrom, window.location.href);
    window.location = `${window.globalConfig.auth.socialUrl}/${encodeURI(provider)}`;
  }

  static logout() {
    localStorage.clear();
    document.dispatchEvent(events.USER_LOGOUT);
  }

  static finishSocialRegistration() {
    const mainPagePath = location.protocol + '//' + location.host;
    if ('token' in window.shared) {
      UserManager.storeToken(window.shared.token);
      localStorage.put(userKey, jwt_decode(window.shared.token));

      const redirectTo = localStorage.get(redirectedFrom) || mainPagePath;
      localStorage.remove(redirectedFrom);
      window.location = redirectTo;
    } else {
      window.location = mainPagePath;
    }
  }
}
