import cookies from '../cookies';
import { sessionStorage } from '../local-storage';
import apiClient from '../api';
import events from '../events';

function umsData(config) {
  return {
    getUser: () => {
      if (!cookies.read(config.TOKEN_NAME)) {
        return false;
      }
      if (sessionStorage.get(config.SESSION_NAME_USER)) {
        return sessionStorage.get(config.SESSION_NAME_USER);
      }

      return apiClient.get(config.getUrl).then(res => {
        if (!res.data) {
          return false;
        }
        const user = res.data;
        sessionStorage.put(config.SESSION_NAME_USER, user);
        document.dispatchEvent(new CustomEvent(events.AUTHENTICATED, { detail: { provider: config.DOMAIN, user } }));

        return user;
      });
    },
    logOut: () => {
      cookies.erase(config.TOKEN_NAME);
      cookies.erase(config.REFRESH_TOKEN_NAME);
      sessionStorage.remove(config.SESSION_NAME_USER);
    },
  };
}

export default umsData;
