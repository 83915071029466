import { localStorage, sessionStorage } from '@core/components/common/local-storage';
import cookies from '@core/components/common/cookies';
import helpers from '@core/components/common/helpers';
import events from '@core/components/common/events.js';
import apiClient from '@core/components/common/api';
import { UTM_CAMPAIGN, UTM_CONTENT, UTM_MEDIUM, UTM_SOURCE, UTM_TERM } from '../common/utm';

const eventsAnalytics = {
  RETENTION: 'RETENTION',
};

const getAnalyticsData = (source, eventDetails) => {
  const utmTagData = helpers.omitBy(
    {
      utm_source: localStorage.get(UTM_SOURCE),
      utm_medium: localStorage.get(UTM_MEDIUM),
      utm_campaign: localStorage.get(UTM_CAMPAIGN),
      utm_content: localStorage.get(UTM_TERM),
      utm_term: localStorage.get(UTM_CONTENT),
    },
    value => value === undefined || value === null,
  );

  const allCookies = cookies.readAll();
  const url = window.location.pathname;
  const userIds = allCookies && Object.fromEntries(Object.entries(allCookies).filter(([key]) => key[0] === '_'));
  return helpers.omitBy(
    {
      source,
      url,
      userIds,
      eventDetails,
      utmTagData,
    },
    value => value === undefined || value === null,
  );
};

export default function SendLoginEvent() {
  document.addEventListener(events.AUTHENTICATED, function () {
    const retention = sessionStorage.get(eventsAnalytics.RETENTION);
    if (!retention) {
      sessionStorage.put(eventsAnalytics.RETENTION, true);
      apiClient.post(
        window.globalConfig.lectera.aggregate,
        getAnalyticsData(eventsAnalytics.RETENTION, { event: 'retention' }),
      );
    }
  });
}
