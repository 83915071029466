'use strict';
/**
 * Libs
 */
import './global-config';
/**
 * App
 */
//Internal components
// import SearchForm from '@core/components/widgets/search-form';
import SPA from './components/common/barba/barba';
import { StoreUtm } from './components/common/utm';
import SendLoginEvent from './components/lectera/integration';
import { initAll } from './components/common/barba/initScripts';

document.addEventListener('DOMContentLoaded', () => {
  StoreUtm();
  SPA();
  SendLoginEvent();
  initAll().then();
});
