import cookies from '@core/components/common/cookies';
import { disabledScroll, enableScroll } from '@ui/utils';

export const PopupDesktopOut = () => {
  const threshold = 4;
  const delay = 550;
  const showClassName = 'd-block';
  const alreadyShowedName = 'showedFreezePopup';
  const modalPromo = document.querySelector('.promo-modal');
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Dolfin|MobileSafari|Opera Mini/i.test(
    navigator.userAgent,
  );

  const token = cookies.read('accessToken');

  if (isMobile || !modalPromo || token || sessionStorage.profile) {
    return;
  }

  const close = modalPromo.querySelector('.close');

  if (!close) {
    return;
  }

  window.onbeforeunload = function () {
    sessionStorage.removeItem(alreadyShowedName);
  };

  const leaveListener = function (event) {
    if (event.clientY > threshold || sessionStorage.getItem(alreadyShowedName) === 'y') {
      return;
    }
    modalPromo.classList.add(showClassName);
    sessionStorage.setItem(alreadyShowedName, 'y');
    disabledScroll();
  };

  const closePopUp = () => {
    modalPromo.classList.remove(showClassName);
    document.documentElement.removeEventListener('mouseleave', leaveListener);
    enableScroll();
  };

  if (sessionStorage.getItem(alreadyShowedName) === 'y') {
    return;
  }

  setTimeout(() => {
    modalPromo.addEventListener('click', function (event) {
      if (event.target === modalPromo) {
        closePopUp();
      }
    });

    close.addEventListener('click', function () {
      closePopUp();
    });

    document.documentElement.addEventListener('mouseleave', leaveListener);
  }, delay);
};
