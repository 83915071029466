import { CLASSES, LANGUAGE_MENU, MENUS_ID, OPEN_ICON, CLOSE_ICON } from '../constans';
import { enableScroll, disabledScroll } from '../utils';

function toggleMenu(button, overlay) {
  const menuId = button.getAttribute('data-menu-id');
  const menu = document.getElementById(menuId);

  if (menu.classList.contains(CLASSES.OPEN)) {
    closeMenu(menu, button, overlay);
  } else {
    closeAllMenus();
    openMenu(menu, button, overlay);
  }
}

function openMenu(menu, button, overlay) {
  menu.classList.add(CLASSES.OPEN);
  button.classList.add(CLASSES.OPEN);
  overlay.classList.add(CLASSES.OPEN);
  toggleMainMenu(menu, button);
}

function closeMenu(menu, button, overlay) {
  menu.classList.remove(CLASSES.OPEN);
  button.classList.remove(CLASSES.OPEN);
  overlay.classList.remove(CLASSES.OPEN);
  toggleMainMenu(menu, button);
}

function toggleMainMenu(menu, button) {
  const buttons = document.querySelectorAll('.courses-button, .education-button');
  const isOpen = button.innerHTML.includes('menuIconOpen');
  button.innerHTML = isOpen ? CLOSE_ICON : OPEN_ICON;
  if (menu.getAttribute('id') === MENUS_ID.MAIN_MENU) {
    buttons.forEach(btn => {
      btn.classList.toggle(CLASSES.DISPLAY_NONE, !btn.classList.contains(CLASSES.DISPLAY_NONE));
    });
  }
}

function toggleBackBtn() {
  const headerContent = document.getElementById(LANGUAGE_MENU.HEADER_CONTENT);
  const backButton = document.getElementById(LANGUAGE_MENU.BACK_BUTTON);

  headerContent.classList.toggle('d-none', !headerContent.classList.contains('d-none'));
  backButton.classList.toggle('d-flex', !backButton.classList.contains('d-flex'));
}

export function closeAllMenus() {
  const menus = document.querySelectorAll(CLASSES.MENU);
  const buttons = document.querySelectorAll(CLASSES.MENU_BUTTON);
  const overlay = document.querySelector(CLASSES.OVERLAY);

  menus.forEach(menu => {
    menu.classList.remove(CLASSES.OPEN);
  });

  buttons.forEach(button => {
    button.classList.remove(CLASSES.OPEN);
  });

  overlay.classList.remove(CLASSES.OPEN);
  enableScroll();
}

function CategoriesMobileHandler() {
  const button = document.querySelector('.categories-button-mobile');
  const menu = document.getElementById(MENUS_ID.CATEGORIES_MENU);
  button.addEventListener('click', () => {
    toggleBackBtn();
    menu.classList.add(CLASSES.OPEN);
  });
}

export function HeaderMenuHandler() {
  const buttons = document.querySelectorAll(CLASSES.MENU_BUTTON);
  const overlay = document.querySelector(CLASSES.OVERLAY);

  buttons.forEach(button => {
    button.addEventListener('click', () => toggleMenu(button, overlay));
  });

  overlay.addEventListener('click', () => closeAllMenus());

  CategoriesMobileHandler();
}

export function LanguageMenuHandler() {
  const { ID, BACK_BUTTON, BUTTON, OPEN_CLASS } = LANGUAGE_MENU;

  const languageMenuMobile = document.getElementById(ID);
  const backButton = document.getElementById(BACK_BUTTON);
  const button = document.getElementById(BUTTON);

  const toggleMenu = () => {
    languageMenuMobile.classList.toggle(OPEN_CLASS, !languageMenuMobile.classList.contains(OPEN_CLASS));
    toggleBackBtn();
    if (document.body.style.overflow !== 'hidden') {
      disabledScroll();
    } else {
      enableScroll();
    }
  };

  button.addEventListener('click', toggleMenu);
  backButton.addEventListener('click', closeAllMobbileMenu);
}

function closeAllMobbileMenu() {
  const menus = document.querySelectorAll('.menu-mobile');

  menus.forEach(menu => {
    menu.classList.remove(CLASSES.OPEN);
  });

  toggleBackBtn();
}

export function ProfileMenuHandler() {
  const menuButtons = document.querySelectorAll('.auth-button');
  menuButtons.forEach(btn => {
    btn.addEventListener('click', () => {
      if (btn.classList.contains('auth-mobile')) {
        toggleBackBtn();
      } else {
        closeAllMenus();
      }
      const menuId = btn.getAttribute('data-menu-id');
      const menu = document.getElementById(menuId);
      menu.classList.add(CLASSES.OPEN);
      menu.addEventListener('click', () => {
        menu.classList.remove(CLASSES.OPEN);
      });
    });
  });
}

function toggleMenuFooter(button) {
  const buttons = document.querySelectorAll(CLASSES.FOOTER_MENU_BUTTON);
  buttons.forEach(btn => {
    const parent = btn.parentNode;
    const menu = parent.querySelector(CLASSES.FOOTER_MENU);
    if (btn !== button) {
      menu.classList.add(CLASSES.DISPLAY_NONE);
      btn.classList.remove(CLASSES.OPEN);
    }
  });

  const parent = button.parentNode;
  const menu = parent.querySelector(CLASSES.FOOTER_MENU);
  menu.classList.toggle(CLASSES.DISPLAY_NONE);
  button.classList.toggle(CLASSES.OPEN);
}

export function FooterMenuHandler() {
  const buttons = document.querySelectorAll(CLASSES.FOOTER_MENU_BUTTON);
  buttons.forEach(button => {
    button.addEventListener('click', () => toggleMenuFooter(button));
  });
}
