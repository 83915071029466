import * as utils from './utils';

const createStorage = (slider, slide) =>
  setStorage(slider, {
    type: slider.dataset.type,
    slide: slide,
    showCounters: slider.dataset.counters,
    answers: {},
  });

const setStorage = (slider, data) => localStorage.setItem(`quiz_id_${slider.dataset.id}`, JSON.stringify(data));

const getStorage = slider => JSON.parse(localStorage.getItem(`quiz_id_${slider.dataset.id}`));

const updateStorage = (slider, slides, index) => {
  let data = getStorage(slider);

  data.slide = index + 1;

  switch (data.type) {
    case 'trivia':
    case 'personal':
      // eslint-disable-next-line no-case-declarations
      const radio = utils.getSlideRadio(slides, index);

      if (radio) {
        data.answers[radio.name] = radio.value;
        setStorage(slider, data);
      } else {
        return false;
      }
      break;
    case 'range':
      utils.getSlideRange(slides, index).forEach(range => {
        data.answers[range.name] = range.value;
      });

      setStorage(slider, data);
      break;
  }

  return true;
};

const updateStorageWithResult = (slider, response) => {
  const {
    data: { result, counters },
  } = response;

  let data = getStorage(slider);

  data.result = result;
  data.counters = counters;

  setStorage(slider, data);
};

const updateStorageCounters = (slider, requestCounters) => {
  const data = getStorage(slider);
  const userResultId = data.result.id;

  requestCounters.forEach(requestCounter => {
    if (requestCounter.id === userResultId) {
      data.result.counter = requestCounter.counter;
      return;
    }

    data.counters.forEach((counter, index, dataCounters) => {
      if (requestCounter.id === counter.id) {
        dataCounters[index].counter = requestCounter.counter;
      }
    });
  });

  setStorage(slider, data);

  return data;
};

const refreshStorage = slider => {
  let data = getStorage(slider);

  data.slide = 1;
  data.answers = {};
  data.result = undefined;
  data.counters = undefined;

  setStorage(slider, data);

  return data.slide;
};

export {
  createStorage,
  setStorage,
  getStorage,
  updateStorage,
  refreshStorage,
  updateStorageWithResult,
  updateStorageCounters,
};
