import { getBlockIndexName, getName } from './analytics';

export default function SendViewToGoogleAnalytics() {
  const isVisible = function (element) {
    const position = element.getBoundingClientRect();
    if (position.top < window.innerHeight && position.bottom >= 0) {
      return true;
    }

    if (element.getAttribute('bigBrothers')) {
      element.removeAttribute('bigBrothers');
    }

    return false;
  };

  let timerId = 0;

  const sendView = () => {
    const coursesElems = document.querySelectorAll('.courses-swiper');
    window.dataLayer = window.dataLayer || [];
    if (coursesElems.length === 0 || !window.dataLayer) {
      return;
    }

    coursesElems.forEach((element, courseBlockIndex) => {
      const promoView = {
        ecommerce: {
          promoView: {
            promotions: [],
          },
        },
      };

      if (!isVisible(element) || element.getAttribute('bigBrothers') === 'ISeeYourScreen') {
        return;
      }
      element.setAttribute('bigBrothers', 'ISeeYourScreen');
      const courseLinks = element.querySelectorAll('a.course-card');
      if (courseLinks.length === 0) {
        return;
      }

      const collected = {};

      courseLinks.forEach((linkElement, linkIndex) => {
        const courseTitle = linkElement.querySelector('.course-title').textContent;
        if (Object.prototype.hasOwnProperty.call(collected, courseTitle)) {
          return;
        }
        collected[courseTitle] = 1;
        promoView.ecommerce.promoView.promotions.push({
          name: getName(),
          creative: courseTitle,
          position: getBlockIndexName(courseBlockIndex) + `_course_${linkIndex}`,
        });
      });

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(promoView);
    });
  };

  // run on first
  document.addEventListener('loadedCoursesWidget', function () {
    sendView();
  });

  window.addEventListener('scroll', () => {
    if (timerId !== 0) {
      return;
    }
    timerId = setTimeout(() => {
      sendView();
      clearTimeout(timerId);
      timerId = 0;
    }, 16.6);
  });
}
