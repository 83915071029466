import { localStorage } from '@core/components/common/local-storage';
import { closeAllMenus } from './menu';
import { disabledScroll, enableScroll } from '../utils';

function openModal(modal) {
  closeAllMenus();
  disabledScroll();
  modal.style.display = 'block';
}

function closeModal(event, modal, closeButton) {
  if (event.target === modal || event.target === closeButton) {
    modal.style.display = 'none';
    enableScroll();
  }
}

export function Modal(openButtonClass, modalId, closeButtonId) {
  const openButtons = document.querySelectorAll(`.${openButtonClass}`);
  const modal = document.getElementById(modalId);
  const closeButton = document.getElementById(closeButtonId);

  openButtons.forEach(btn => {
    btn.addEventListener('click', () => openModal(modal));
  });
  modal.addEventListener('click', event => closeModal(event, modal, closeButton));
  closeButton.addEventListener('click', event => closeModal(event, modal, closeButton));

  return {
    close: () => {
      modal.style.display = 'none';
      enableScroll();
    },
    open: () => openModal(modal),
  };
}

export function LangConfirmView() {
  const languageConfirmModal = document.querySelector('.lang-confirm-modal');
  const buttonsClose = languageConfirmModal.querySelectorAll('.close-modal');

  if (localStorage.get('isLanguageConfirmed') === true) {
    languageConfirmModal.classList.add('d-none');
    return;
  }

  buttonsClose.forEach(btn => {
    btn.addEventListener('click', () => {
      languageConfirmModal.classList.add('d-none');
    });
  });

  languageConfirmModal.classList.remove('d-none');
  localStorage.put('isLanguageConfirmed', true);
}
