import barba from '@barba/core';
import { initAll } from './initScripts';
import { enableScroll, showPreloader } from '@ui/utils';

const SPA = () => {
  let timeHandler = 0;
  barba.init({
    prevent: ({ el }) => el.pathname.split('/').at(1) !== 'magazine',
    timeout: 7500,
  });
  barba.hooks.before(({ current }) => {
    timeHandler = setTimeout(() => {
      showPreloader(current.container);
    }, 500);
  });

  barba.hooks.afterLeave(() => {
    document.querySelector('.overlay').classList.remove('open');
    enableScroll();
  });

  barba.hooks.after(() => {
    clearTimeout(timeHandler);
    timeHandler = 0;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    initAll().then();
  });
};
export default SPA;
