import apiClient from '@core/components/common/api';
import cookies from '@core/components/common/cookies';

const TOKEN_NAME = window.globalConfig.lectera.tokenName;

export default function Pulse() {
  if (!window?.globalConfig?.lectera?.pulse) {
    return;
  }

  if (cookies.read(TOKEN_NAME)) {
    apiClient.post(window.globalConfig.lectera.pulse);
  }
}
