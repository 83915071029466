import axios from 'axios';
import Snackbar from '@core/components/widgets/snackbar';
import { validateEmail } from '../utils';
import { HELP_FORM, SVG_HTML_REMOVE } from '../constans';
import { Modal } from './modal';

export const HelpForm = () => {
  const { close } = Modal(HELP_FORM.HELP_MODAL_BUTTON, HELP_FORM.HELP_MODAL, HELP_FORM.HELP_MODAL_CLOSE);
  const form = document.getElementById(HELP_FORM.FORM_ID);
  const uploadFilesBtn = document.querySelector(HELP_FORM.FILE_BTN);
  const addFilesBtn = document.querySelector(HELP_FORM.ADD_FILE_BTN);
  const hideFileInput = document.querySelector(HELP_FORM.HIDE_INPUT);
  let selectedFiles = [];
  const processForm = (token, formData, form) => {
    const titleFormOk = form.dataset.ok;
    const messageFormOk = form.dataset.okMessage;
    const titleFormError = form.dataset.error;
    const messageFormError = form.dataset.errorNetwork;

    axios
      .post(window.globalConfig.lectera.help, formData, {
        headers: {
          'Captcha-Response': token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        switch (response.status) {
          case 200:
            Snackbar.show(titleFormOk, messageFormOk);
            close();
            break;
          default:
            Snackbar.show(messageFormError, messageFormError, true);
        }
      })
      .catch(function () {
        Snackbar.show(titleFormError, messageFormError, true);
      });
  };

  uploadFilesBtn.addEventListener('click', () => {
    hideFileInput.click();
  });

  addFilesBtn.addEventListener('click', function () {
    hideFileInput.click();
  });

  hideFileInput.addEventListener('change', function (event) {
    const files = event.target.files;
    const fileList = document.querySelector(HELP_FORM.FILE_LIST);

    uploadFilesBtn.classList.add('d-none');

    for (let i = 0; i < files.length; i++) {
      selectedFiles.push(files[i]);
      const li = document.createElement('li');
      li.classList = HELP_FORM.FILE_LIST_ITEM;
      li.textContent = files[i].name;
      const removeBtn = document.createElement('button');
      removeBtn.classList = HELP_FORM.REMOVE_FILE_BTN;
      removeBtn.innerHTML = SVG_HTML_REMOVE;
      removeBtn.addEventListener('click', function () {
        const index = selectedFiles.indexOf(files[i]);
        if (index > -1) {
          selectedFiles.splice(index, 1);
        }
        fileList.removeChild(li);

        if (selectedFiles.length === 0) {
          uploadFilesBtn.classList.remove('d-none');
          addFilesBtn.classList.add('d-none');
        }
      });
      li.insertBefore(removeBtn, li.firstChild);
      fileList.appendChild(li);
    }

    addFilesBtn.classList.remove('d-none');
  });

  form.addEventListener('submit', event => {
    event.preventDefault();
    const formData = new FormData(form);
    const email = document.getElementById(HELP_FORM.EMAIL_INPUT_ID);

    if (!validateEmail(email.value)) {
      return;
    }

    const fileInput = document.querySelector('input[name="attachments"]');
    if (fileInput && fileInput.files.length === 0) {
      formData.delete('attachments'); // Удаляем данные файла из FormData, если файлы не выбраны
    }

    window.grecaptcha.ready(function () {
      window.grecaptcha.execute(window.globalConfig.googleCaptcha, { action: 'social' }).then(function (token) {
        processForm(token, formData, form);
        form.reset();
        selectedFiles = [];
      });
    });
  });
};
