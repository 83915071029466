import SendViewToGoogleAnalytics from '@integration/analytics/view-analytics';
import CourseSlider from '@integration/courses';
import TrackViews from '@core/components/widgets/track-views';
import flipCardQuiz from '@quizzes/flipcard';
import quizSlider from '@quizzes/quiz-slider';
import Pulse from '@integration/lectera/pulse';
import CheckUser from '@ui/check-user/check-user';
import { FooterMenuHandler, HeaderMenuHandler, LanguageMenuHandler, ProfileMenuHandler } from '@ui/widgets/menu';
import { LangConfirmView, Modal } from '@ui/widgets/modal';
import { HelpForm } from '@ui/widgets/help-form';
import { PopupDesktopOut } from '../../widgets/popup-desktop-out';
import LoaderThirdPartScrips from '../loader-third-part-scripts';
import ArticleSlider from '@core/components/widgets/swiper';

const QuizInit = () => {
  const quizFlipCard = document.querySelectorAll('.quiz[data-type="flipcard"]');
  const sliders = document.querySelectorAll(`
                .quiz-slider[data-type="range"],
                .quiz-slider[data-type="trivia"],
                .quiz-slider[data-type="personal"]
                `);
  sliders.forEach(element => quizSlider(element));
  flipCardQuiz(quizFlipCard);
};

export const initAll = async () => {
  ArticleSlider();
  CheckUser().then();
  HeaderMenuHandler();
  FooterMenuHandler();
  LanguageMenuHandler();
  ProfileMenuHandler();
  LangConfirmView();
  HelpForm();
  QuizInit();
  SendViewToGoogleAnalytics();
  CourseSlider();
  TrackViews();
  PopupDesktopOut();
  LoaderThirdPartScrips();
  Pulse();
  Modal('lang-modal-btn', 'languageModal', 'languageModalClose');
};
