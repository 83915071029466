import { localStorage } from '@core/components/common/local-storage';

export const UTM_SOURCE = 'utm_source';
export const UTM_MEDIUM = 'utm_medium';
export const UTM_CAMPAIGN = 'utm_campaign';
export const UTM_TERM = 'utm_term';
export const UTM_CONTENT = 'utm_content';

const updateUtmCookie = (utmSlug, value) => {
  if (value) {
    localStorage.put(utmSlug, value);
  } else {
    localStorage.remove(utmSlug);
  }
};

const queryStringToObject = url =>
  [...new URLSearchParams(url.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {});

export function StoreUtm() {
  const {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_content: utmTerm,
    utm_term: utmContent,
  } = queryStringToObject(window.location.search);
  if (utmSource || utmMedium || utmSource || utmCampaign || utmTerm || utmContent) {
    updateUtmCookie(UTM_SOURCE, utmSource);
    updateUtmCookie(UTM_MEDIUM, utmMedium);
    updateUtmCookie(UTM_CAMPAIGN, utmCampaign);
    updateUtmCookie(UTM_TERM, utmTerm);
    updateUtmCookie(UTM_CONTENT, utmContent);
  }
}
